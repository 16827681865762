import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import browserUpdate from 'browser-update'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import 'react-app-polyfill/stable'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { createRoot } from 'react-dom/client'
import { pdfjs } from 'react-pdf'
import 'resize-observer-polyfill'
import theme from 'theme'

import { getIsGov } from 'contexts/ClientType'

import { initTracking } from 'tracking'

import App from './App'
import './styles/markdown.css'
import './styles/printing.css'
import './styles/styles.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

// This at the time of committing was up-to-date with browserlist
const supportedBrowserOptions = {
  required: {
    e: 112,
    i: 12, // This should disallow IE
    f: 102,
    o: 98,
    s: 15.6,
    c: 109,
    samsung: 20,
  },
  insecure: true,
}
browserUpdate(supportedBrowserOptions)

const isGov = getIsGov()

const sentryDSN = isGov
  ? process.env.REACT_APP_SENTRY_DSN_GOV
  : process.env.REACT_APP_SENTRY_DSN

if (process.env.NODE_ENV === 'production' && !!sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend(event, hint) {
      if (
        hint?.originalException instanceof Error &&
        hint?.originalException?.message === 'Network Error'
      ) {
        return null
      }
      return event
    },
  })
}

initTracking()
const root = createRoot(document.getElementById('root')!)
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <ChakraProvider theme={theme} resetCSS>
      <App />
    </ChakraProvider>
  </Sentry.ErrorBoundary>
)
