import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { createContext, useCallback, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

import { IApp } from 'interfaces/navigationApp.interface'

import useTracking from 'tracking/useTracking'

import { AccessGroups } from 'enums/AccessGroups'

import {
  AppletGroupConfig,
  appletGroupListUnion,
  appletGroups,
} from 'config/appletGroups'
import { AppletConfig, appletListUnion, applets } from 'config/applets'
import { apps, appsListUnion } from 'config/apps'

import useAxios from '../api/useAxios'
import { getIsGov } from './ClientType'

export interface UserClaims {
  username: string
  groups: string[]
  name: string
  client?: string
  title?: string
  is_staff: boolean
  access_rights: Record<AccessGroups, { tier: 'basic' | 'standard' }>
}

type AuthContextType = {
  userInfo: UserClaims | null
  userIsBasicWithinApp: (app?: IApp) => boolean
  userIsStaff: boolean
  userCanAccessApp: (app: IApp) => boolean
  appListUserCanAccess: IApp[]
  firstAppUserCanAccess?: IApp
  userCanAccessAPI: boolean
  userCanAccessTutorial: boolean
  appletsUserCanSee: Partial<Record<appletListUnion, AppletConfig>>
  appletGroupsUserCanSee: Partial<
    Record<appletGroupListUnion, AppletGroupConfig>
  >
}

const defaultContext: AuthContextType = {
  userInfo: null,
  userIsBasicWithinApp: () => false,
  userIsStaff: false,
  appListUserCanAccess: [],
  userCanAccessApp: () => false,
  userCanAccessAPI: true,
  userCanAccessTutorial: true,
  appletsUserCanSee: {},
  appletGroupsUserCanSee: {},
}

export const AuthContext = createContext<AuthContextType>(defaultContext)

export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [tracking] = useTracking()
  const auth = useAuth()

  const isGov = getIsGov()

  const axios = useAxios()

  const { data: userInfo } = useQuery<UserClaims | null>(
    ['user', auth.isAuthenticated, tracking],
    () => {
      if (!auth.isAuthenticated) {
        Sentry.configureScope((scope) => scope.setUser(null))
        return null
      } else {
        return axios.get('/user/').then((response) => {
          const userInfo: Omit<UserClaims, 'groups'> = response.data
          const groups = Object.keys(userInfo.access_rights)
          if (!isGov) {
            tracking.identifyUser({
              userId: userInfo.username,
              name: userInfo.name,
              groups: groups.join(' | '),
              isEmailVerified: true,
              client: userInfo?.client,
              title: userInfo?.title,
            })
          }
          Sentry.setUser({ id: userInfo.username })
          return { ...userInfo, groups }
        })
      }
    }
  )

  const userIsBasicWithinApp = useCallback(
    (app?: IApp) =>
      userInfo && app
        ? Object.keys(userInfo.access_rights)
            .filter((group) => app.accessGroups.includes(group as AccessGroups))
            .some(
              (group) =>
                userInfo.access_rights[group as AccessGroups].tier === 'basic'
            )
        : false,
    [userInfo]
  )

  const userIsStaff = useMemo(
    () => userInfo?.is_staff ?? false,
    [userInfo?.is_staff]
  )

  const userGroupIsWithinApp = useCallback(
    (app: IApp) => {
      const groupsCanAccess = app.accessGroups as string[]
      return userInfo?.groups.some((r) => groupsCanAccess.includes(r)) ?? false
    },
    [userInfo]
  )

  const appListUserCanAccess = useMemo(() => {
    const rawAppsUserCanAccess =
      Object.values(apps).filter(userGroupIsWithinApp)

    return rawAppsUserCanAccess.filter((app) => {
      // If no appIdentifier passed, then we can just show it
      if (app.appIdentifier === null || app.appIdentifier === undefined) {
        return true
      }

      // Unless the user is Staff, then ignore appIdentifier
      if (userIsStaff) {
        return true
      }

      // If appIdentifier exist, then we want to find all that are similar and find the one with highest appPriority
      const appsWithSameIdentifier = rawAppsUserCanAccess.filter(
        (insideApp) => insideApp.appIdentifier === app.appIdentifier
      )

      const currentAppIsHighestPriority =
        appsWithSameIdentifier.reduce(
          (acc, val) =>
            (val.appPriority ?? 0) > (acc.appPriority ?? 0) ? val : acc,
          appsWithSameIdentifier[0]
        ).slug === app.slug

      return currentAppIsHighestPriority
    })
  }, [userGroupIsWithinApp, userIsStaff])

  const userCanAccessApp = useCallback(
    (app: IApp) => {
      return !!appListUserCanAccess.find(
        (appUserCanAccess) => appUserCanAccess.slug === app.slug
      )
    },
    [appListUserCanAccess]
  )

  const firstAppUserCanAccess = useMemo(
    () =>
      appListUserCanAccess.length > 0 ? appListUserCanAccess[0] : undefined,
    [appListUserCanAccess]
  )

  const userCanAccessAPI = useMemo(
    () =>
      !!userInfo?.groups.includes(AccessGroups.APIForecast) ||
      !!userInfo?.groups.includes(AccessGroups.APIEpidId) ||
      !!userInfo?.groups.includes(AccessGroups.APIOutbreak) ||
      !!userInfo?.groups.includes(AccessGroups.APIReport) ||
      !!userInfo?.groups.includes(AccessGroups.APISiga),
    [userInfo?.groups]
  )

  // Applet & applet groups
  const appletsUserCanSee = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(applets)
          .map(
            ([appletKey, applet]) =>
              [
                appletKey,
                // Only show the apps user can access
                {
                  ...applet,
                  apps: applet.apps.filter((appSlug) =>
                    userCanAccessApp(apps[appSlug])
                  ) as appsListUnion[],
                } as AppletConfig,
              ] as const
          )
          // Only show applet if at user can access at least 1 app
          .filter(([appletKey, applet]) => applet.apps.length > 0)
      ) as Record<appletListUnion, AppletConfig>,
    [userCanAccessApp]
  )
  const appletGroupsUserCanSee = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(appletGroups)
          .map(
            ([appletGroupKey, appletsGroup]) =>
              [
                appletGroupKey,
                // Only show the apps user can access
                {
                  ...appletsGroup,
                  applets: appletsGroup.applets.filter((appletKey) =>
                    Object.keys(appletsUserCanSee).includes(appletKey)
                  ) as appletListUnion[],
                } as AppletGroupConfig,
              ] as const
          )
          // Only show appletGroup if at user can access at least 1 applet
          .filter(
            ([appletGroupKey, appletGroup]) => appletGroup.applets.length > 0
          )
      ) as Record<appletGroupListUnion, AppletGroupConfig>,
    [appletsUserCanSee]
  )

  // Hard code companies for now
  const whitelistedCompanies = [
    'GSK',
    'Merck',
    'WTO',
    'IMF',
    'Shionogi',
    'Bayer',
    'Merck Sharp & Dohme Corp.',
  ]
  const userCanAccessTutorial =
    userIsStaff || whitelistedCompanies.includes(userInfo?.client ?? '')

  return (
    <AuthContext.Provider
      value={{
        userInfo: userInfo ?? null,
        userIsBasicWithinApp,
        userIsStaff,
        userCanAccessApp,
        appListUserCanAccess,
        firstAppUserCanAccess,
        userCanAccessAPI,
        userCanAccessTutorial,
        appletsUserCanSee,
        appletGroupsUserCanSee,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer
